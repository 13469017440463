$flag-icons-path: '~flag-icons/flags';
$flag-icons-rect-path: '/4x3' !default;
$flag-icons-square-path: '/1x1' !default;
$flag-icons-use-square: false !default;

@import '~flag-icons/sass/flag-icons';

.flag-icon-none {
  background-color: black;
}
